
























































































import Vue from 'vue';
import { PHOTOVOLTAIK_INSURANCE } from '@/constant/constants';
import { dataChangeMixin } from '@/mixins/dataChangeMixin';
import ZuersZone from '@/components/form/zuers-zone.vue';
import { mapMutations } from 'vuex';

export default Vue.extend({
  name: 'photovoltaics-insurance-basic',
  mixins: [dataChangeMixin],
  components: {
    ZuersZone,
  },
  data() {
    return {
      key: PHOTOVOLTAIK_INSURANCE,
      versicherungsort: {
        Strabe: '',
        PLZ: '',
        Ort: '',
        Nr: '',
        ZursZone: '',
        Starkregenzone: '',
        ArtDerAnlage: null,
      },
    };
  },
  watch: {
    vuexZurs(newValue: string) {
      this.versicherungsort.ZursZone = newValue;
      this.versicherungsort.Starkregenzone = newValue;
    },
  },
  props: ['insuranceKey', 'caseStatus', 'insuranceData'],
  methods: {
    ...mapMutations('zuersZoneModule', {
      updatePlace: 'SET_PLACE',
      updatePostalCode: 'SET_POSTAL_CODE',
      updateStreet: 'SET_STREET',
      updateHouseNumber: 'SET_HOUSE_NUMBER',
      updateZurs: 'SET_ZURS',
      resetAddres: 'RESET_STATE',
    }),
    setZuersAddressToStore() {
      this.resetAddres();
      this.updatePlace(this.versicherungsort.Ort);
      this.updatePostalCode(this.versicherungsort.PLZ);
      this.updateStreet(this.versicherungsort.Strabe);
      this.updateHouseNumber(this.versicherungsort.Nr);
      this.updateZurs(this.versicherungsort.ZursZone);
    },
    setValues(basicData: any) {
      this.versicherungsort = basicData.versicherungsort;
    },
    onSubmit() {
      this.submitForm();
    },
    submitForm(goNextPage = true) {
      const data = {
        versicherungsort: this.versicherungsort,
      };

      if (goNextPage) {
        this.$emit('basic-data-change', data, this.key);
      } else {
        this.$emit('basic-data-change', data, this.key, 1);
      }
    },
  },
  mounted() {
    (this as any).resetWatcherActivated();
    this.$emit('on-key-change', this.key);
    if (this.insuranceData && this.insuranceData.basicData) {
      this.setValues(this.insuranceData.basicData);
    }
    this.setZuersAddressToStore();
    (this as any).startWatcherActivated();
  },
  computed: {
    vuexZurs(): number {
      return this.$store.getters['zuersZoneModule/getZurs'];
    },
  },
});
